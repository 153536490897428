import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, CircularProgress } from '@mui/joy';
import api from '../config/axiosConfig';

const UserProfileComments = ({ username }) => {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1); // For pagination
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(null);
    const initialLoadDone = useState(false);

    const fetchUserComments = async (username, page) => {
        try {
            const response = await api.get(`/api/comments`, {
                params: {
                    username,
                    limit: 5,  // Increase the limit to ensure more content is fetched initially
                    offset: (page - 1) * 5,
                },
            });

            if (response.data.length < 5) {
                setHasMore(false); // If less than 10 entities are fetched, there are no more pages
            }

            setComments(prevComments => {
                const newComments = response.data.filter(
                    newComment => !prevComments.some(comment => comment.eid === newComment.eid)
                );
                return [...prevComments, ...newComments];
            });

            setLoading(false);
        } catch (err) {
            setError('Failed to load comments');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserComments(username, page);
    }, [username, page]);

    useEffect(() => {
        const handleScroll = (e) => {
            if (loading || !hasMore) return;

            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
                setPage(prevPage => prevPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);

    // Ensure that more content is loaded if the content does not fill the screen
    useEffect(() => {
        if (!initialLoadDone.current && !loading && hasMore && document.documentElement.scrollHeight <= window.innerHeight) {
            initialLoadDone.current = true;
            setPage(prevPage => prevPage + 1);
        }
    }, [loading, hasMore, initialLoadDone]);

    if (loading && page === 1) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body2" color="error">{error}</Typography>;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pb: 4 }}>  {/* Added padding to the bottom to ensure there is room to scroll */}
            {comments.map((comment) => (
                <Card key={comment.eid} variant="outlined" sx={{ p: 2 }}>
                    <Typography level="h6" sx={{ mb: 1 }}>
                        {username}
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                        {comment.content}
                    </Typography>
                    <Typography level="body3" sx={{ textAlign: 'right', color: 'neutral.500' }}>
                        {new Date(comment.updated_ts).toLocaleString()}
                    </Typography>
                </Card>
            ))}
            {loading && <CircularProgress sx={{ alignSelf: 'center', mt: 2 }} />}
        </Box>
    );
};

export default UserProfileComments;