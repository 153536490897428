import React from 'react';
import { useParams } from 'react-router-dom';
import EnemiesList from './EnemiesList';
import './MainPage.css';

const MainPage = ({ currentUser }) => {
    const { sort } = useParams();

    return (
        <div className="main-page">
            <EnemiesList currentUser={currentUser} sort={sort}/>
        </div>
    );
};

export default MainPage;