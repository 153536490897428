import React, { useState, useEffect } from 'react';
import api from '../config/axiosConfig';
import AuthModal from './AuthModal';
import { useNavigate} from 'react-router-dom';
import { Textarea, Button, FormLabel, Select, Option, CircularProgress } from '@mui/joy';
import './ImpactProfile.css';

const ImpactEditor = ({ currentUser, impact, description, scope, referenceLink, editMode = true }) => {
    const navigate = useNavigate();
    const [newDescription, setDescription] = useState(description);
    const [newScope, setScope] = useState(scope);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [authorized, setAuthorized] = useState(true); 

    useEffect(() => {
      if (!currentUser) {
        setAuthorized(false);  
        setShowAuthModal(true);
      } else if (currentUser.id !== impact.user_id) {
        setAuthorized(false);  
        navigate(`/impact/${impact.id}`);
        window.location.reload();
      }
    }, [currentUser, impact, navigate]);

    if (!authorized) {
      return null; // Prevent rendering if not authorized
    }

    const handleScopeChange = (e, newValue) => setScope(newValue);
    const handleDescriptionChange = (e) => {
      setDescription(e.target.value);
    };
  
    const handleSave = async (e) => {
      e.preventDefault();
      try {
          await api.patch(`/api/impacts/id/${impact.id}`, { description: newDescription, scope: newScope, reference_link: referenceLink });
          editMode = false;
          navigate(`/impact/${impact.id}`);
          window.location.reload();
      } catch (error) {
          console.error('Error saving impact:', error);
      }
  };
    
      const handleCancel = () => {
        editMode = false;
        navigate(`/impact/${impact.id}`);
        window.location.reload();
    };

    if (!impact) {
        return <div><CircularProgress /></div>;
    }

    return (
        <div className="card-margin">
            <div> 
            {impact && (        
            <div className="edit-impact-card">
              <h3 className="impact-title">{impact.title}</h3>
                <FormLabel id="input-scope-select-label">Scope</FormLabel>
                <Select
                    labelid="scope-select-label"
                    id="scope-select"
                    defaultValue={scope}
                    label="Scope"
                    onChange={handleScopeChange}
                >
                    <Option value={"Local"}>Local</Option>
                    <Option value={"National"}>National</Option>
                    <Option value={"Global"}>Global</Option>
                </Select>
                <Textarea
                label="Description"
                minRows={8}
                maxRows={1024}
                defaultValue={description}
                onChange={handleDescriptionChange}
              />
              <div className="edit-actions">
                <Button variant="contained" color="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save
                </Button>
              </div>
              {impact.reference_link && (
                <a href={impact.reference_link} target="_blank" rel="noopener noreferrer" className="impact-card-link">
                    {impact.reference_link}
                </a>
            )}
            </div>
          )}
          </div>
          {showAuthModal && <AuthModal show={showAuthModal} onClose={() => setShowAuthModal(false)} />}
        </div>
    );
};

export default ImpactEditor;