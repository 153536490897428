import React, { useState, useEffect } from 'react';
import { Modal, ModalDialog, Button, FormControl, FormLabel, Input, Select, Option, Textarea, FormHelperText, Typography, Link as JoyLink } from '@mui/joy';
import api from '../config/axiosConfig';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import AuthModal from './AuthModal';

const AddEnemy = ({ currentUser, open, onClose }) => {
    const [wikipediaLink, setWikipediaLink] = useState('');
    const [name, setName] = useState('');
    const [status, setStatus] = useState('');
    const [scope, setScope] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [existingEnemyLink, setExistingEnemyLink] = useState('');

    const validateWikipediaLink = (link) => {
        const regex = /^https:\/\/en\.wikipedia\.org\/wiki\/[a-zA-Z0-9_().,-]+$/;
        return regex.test(link);
    };

    const handleWikipediaLinkChange = (e) => {
        const value = e.target.value;
        setWikipediaLink(value);
        if (validateWikipediaLink(value)) {
            const namePart = decodeURIComponent(value.split('/wiki/')[1]).replace(/_/g, ' ');
            setName(namePart);
            setError('');
            setSubmitEnabled(true);
        } else {
            setError('Invalid Wikipedia link. Example: https://en.wikipedia.org/wiki/Money');
            setSubmitEnabled(false);
        }
    };

    useEffect(() => {
        const checkIfEnemyExists = async () => {
            if (name) {
                try {
                    const response = await api.get(`/api/enemies?search=${encodeURIComponent(name)}`);
                    if (response.data.length > 0) {
                        const existingEnemy = response.data.find(enemy => enemy.name.toLowerCase() === name.toLowerCase());
                        if (existingEnemy) {
                            const enemyUrl = `/enemy/${encodeURIComponent(existingEnemy.name.replace(/ /g, '_'))}`;
                            setExistingEnemyLink(enemyUrl);
                            setError(`The enemy already exists: `);
                            setSubmitEnabled(false);
                        } else {
                            setError('');
                            setExistingEnemyLink('');
                            setSubmitEnabled(true);
                        }
                    } else {
                        setError('');
                        setExistingEnemyLink('');
                        setSubmitEnabled(true);
                    }
                } catch (err) {
                    console.error('Failed to check if enemy exists:', err);
                }
            }
        };

        checkIfEnemyExists();
    }, [name]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!currentUser) {
            setShowAuthModal(true);
            return;
        }

        try {
            const standardizedLink = wikipediaLink.replace(/^https?:\/\/(www\.)?wikipedia\.org\/wiki\//, 'https://en.wikipedia.org/wiki/');
            const response = await api.post('/api/enemies', {
                wikipedia_link: standardizedLink,
                status,
                scope,
                description,
                user_id: currentUser.id
            });
            if (response.status === 201) {
                window.location.href = `/enemy/${encodeURIComponent(name.replace(/ /g, '_'))}?upvoted=true`;
            }
        } catch (error) {
            console.error('Failed to create enemy:', error);
            setError('Failed to create enemy. Please try again later.');
        }
    };

    return (
        <>
            <Modal open={open} onClose={onClose} size='lg'>
                <ModalDialog>
                    <Typography level="h4" sx={{ mb: 2 }}>
                        Add a New Enemy
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <FormControl required>
                            <FormLabel>Wikipedia Link</FormLabel>
                            <Input
                                type="text"
                                value={wikipediaLink}
                                onChange={handleWikipediaLinkChange}
                                placeholder="A Wikipedia link, e.g., 'https://en.wikipedia.org/wiki/Crime'"
                            />
                        </FormControl>
                        {error && (
                            <FormHelperText color="danger">
                                {existingEnemyLink ? (
                                    <>
                                        <InfoOutlined color="danger"/><JoyLink href={existingEnemyLink} target="_blank" rel="noopener">
                                            {error}                                    
                                            {name}
                                        </JoyLink>
                                    </>
                                ) : (
                                    <span>{error}</span>
                                )}
                            </FormHelperText>
                        )}
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input type="text" value={name} disabled readOnly sx={{backgroundColor: '#e1e1e1', color: '#000000'}} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>Status</FormLabel>
                            <Select value={status} onChange={(e, newValue) => setStatus(newValue)}>
                                <Option value="">Select Status</Option>
                                <Option value="Emerging">Emerging</Option>
                                <Option value="Established">Established</Option>
                                <Option value="Eroding">Eroding</Option>
                                <Option value="Eliminated">Eliminated</Option>
                            </Select>
                        </FormControl>
                        <FormControl required>
                            <FormLabel>Scope</FormLabel>
                            <Select value={scope} onChange={(e, newValue) => setScope(newValue)}>
                                <Option value="">Select Scope</Option>
                                <Option value="Local">Local</Option>
                                <Option value="National">National</Option>
                                <Option value="Global">Global</Option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Describe the entity and why they should be considered an enemy..."
                                maxLength={4800}
                                minRows={5}
                            />
                        </FormControl>
                        <Button type="submit" sx={{ mt: 2 }} disabled={!submitEnabled}>
                            Submit
                        </Button>
                    </form>
                </ModalDialog>
            </Modal>
            {showAuthModal && <AuthModal show={showAuthModal} onClose={() => setShowAuthModal(false)} />}
        </>
    );
};

export default AddEnemy;
