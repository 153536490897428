import React, { useEffect, useState } from 'react';
import { CircularProgress, Box, Button, Typography } from '@mui/joy';
import api from '../config/axiosConfig';
import CreateSolution from './CreateSolution';
import SolutionCard from './SolutionCard';
import './EnemyProfile.css';

const SolutionsSection = ({ currentUser, enemy, showAuthModal }) => {
    const [solutions, setSolutions] = useState([]);
    const [error, setError] = useState('');
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const fetchSolutions = async () => {
            try {
                const response = await api.get(`/api/solutions/enemy/${enemy.id}`);
                const solutionIds = response.data.map(solution => solution.solution_id);

                if (solutionIds.length === 0) {
                    setSolutions([]);
                    return;
                }

                const solutionsResponse = await api.get(`/api/solutions?solutionIds=${JSON.stringify(solutionIds)}`);
                setSolutions(solutionsResponse.data);
            } catch (err) {
                setError('Failed to load solutions. Please try again later.');
            }
        };

        if (enemy) {
            fetchSolutions();
        }
    }, [enemy]);

    const handleSolutionAdded = async () => {
        try {
            const response = await api.get(`/api/solutions/enemy/${enemy.id}`);
            const solutionIds = response.data.map(solution => solution.solution_id);

            if (solutionIds.length === 0) {
                setSolutions([]);
                return;
            }

            const solutionsResponse = await api.get(`/api/solutions?solutionIds=${JSON.stringify(solutionIds)}`);
            setSolutions(solutionsResponse.data);
        } catch (err) {
            setError('Failed to load solutions. Please try again later.');
        }
    };

    const handleOpenModal = () => {
        if (!currentUser) {
            showAuthModal(true);
        } else {
            setOpenModal(true);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    if (error) {
        return <div>{error}</div>;
    }

    if (!enemy) {
        return <div><CircularProgress size="lg" /></div>;
    }

    if (!solutions) {
        return <div><CircularProgress size="lg" /></div>;
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mb: 1 }}>
            <Button variant="outlined" onClick={handleOpenModal}>
                Add Solution
            </Button>
            </Box>
            <CreateSolution 
                enemyId={enemy.id} 
                onSolutionAdded={handleSolutionAdded} 
                currentUser={currentUser} 
                open={openModal} 
                onClose={handleCloseModal} 
            />
            {solutions.length === 0 ? (
                <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
                    No solutions exist for this enemy.
                </Typography>
            ) : (
                solutions.map(solution => (
                    <SolutionCard key={solution.id} solution={solution} truncateDescription={true} currentUser={currentUser} />
                ))
            )}
        </Box>
    );
};

export default SolutionsSection;
