import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthModal from './AuthModal';  


const AuthLogin = ({ awaitingUser, currentUser }) => {    
    const navigate = useNavigate();

    useEffect(() => {
        if (!awaitingUser && currentUser) {
            navigate('/', { replace: true });
        } 
    }, [currentUser, awaitingUser, navigate]);

    return (
        <div className="auth-login">
            {!currentUser && !awaitingUser && <AuthModal show={true} onClose={() => null} /> }
        </div>
    );
};

export default AuthLogin;