import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EntityActions from './EntityActions';   
import { Menu, MenuItem, IconButton } from '@mui/joy';
import { ReactComponent as MoreIcon } from '../assets/button-card-actions.svg';
import { ReactComponent as EditIcon } from '../assets/button-edit.svg';
import { ReactComponent as ReportIcon } from '../assets/button-report.svg';
import './EntityCard.css';

const EnemyCard = ({ enemy, showMeta = true, truncateDescription = true, isEnemyProfilePage = false, currentUser }) => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const enemyHref = `/enemy/${encodeURIComponent(enemy.name.replace(/ /g, '_'))}`;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const moreIconRef = useRef(null);

    const truncateText = (text, length) => {
        if (!text || text.length <= length) return text;
        return text.substring(0, length) + '...';
    };

    const handleCardClick = (e) => {
        if (e.target.tagName.toLowerCase() === 'a') return;
        navigate(`${enemyHref}`);
    };

    const handleDescriptionClick = (e) => {
        if (!truncateDescription) return;
        e.preventDefault();
        if (isEnemyProfilePage) {
            setIsExpanded(!isExpanded);
        } else {
            return;
        }
    };

    const handleMenuToggle = (event) => {
        if (anchorEl) {
            setAnchorEl(null); // Close the menu if it's already open
        } else {
            setAnchorEl(event.currentTarget); // Open the menu
        }
    };
    
      const handleMenuClose = () => {
        setAnchorEl(null);
      };
    
      const handleEdit = () => {
        navigate(`${enemyHref}?edit=true`);
      };

    // Close the menu if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchorEl && !moreIconRef.current.contains(event.target)) {
                handleMenuClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [anchorEl]);

    return (
        <div className="container"> 
            <div className="card">
                <div className="entity-actions-container">
                    <EntityActions entity={enemy} entityType="enemy" currentUser={currentUser}/>
                </div>
                <div className="card-content" onClick={handleCardClick}>
                    <div className="card-header">
                        <Link to={enemyHref} className="card-title">
                            {enemy.name}
                        </Link>
                        <div className="card-tags">
                            {enemy.status && <span className="tag status">{enemy.status}</span>}
                            {enemy.scope && <span className="tag scope">{enemy.scope}</span>}
                        </div>
                    </div>
                    <div className="card-description" onClick={handleDescriptionClick}>
                    <p>
                        {truncateDescription && !isExpanded ? truncateText(enemy.description || '', 512) : enemy.description}
                    </p>
                    </div>
                    <a href={enemy.wikipedia_link} target="_blank" rel="noopener noreferrer" className="card-link">
                        {truncateText(enemy.wikipedia_link || '', 64)}
                    </a>
                </div>
                {showMeta && (
                    <div className="card-meta">
                        <Link to={`/user/${enemy.username}`} className="username">{enemy.username}</Link>
                        <span>{new Date(enemy.updated_ts).toLocaleString()}</span>
                        <div className="icon-more"><IconButton onClick={handleMenuToggle}>
                            <MoreIcon />
                        </IconButton></div>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                            ref={moreIconRef}
                            >
                            <div className="icon-edit">{currentUser?.id === enemy.user_id && (
                            <MenuItem onClick={handleEdit}>
                               <button><EditIcon /> </button>
                            </MenuItem>
                            )}</div>
                            <div className="icon-report"><MenuItem onClick={handleMenuClose}>
                            <button> <ReportIcon /></button>
                            </MenuItem></div>
                        </Menu>                        
                    </div>
                )}
            </div>
        </div>
    );
};

export default EnemyCard;
