import React, { useState, useEffect } from 'react';
import api from '../config/axiosConfig';
import EnemyCard from './EnemyCard';
import CircularProgress from '@mui/material/CircularProgress';
import './EntityCard.css';
import { Select, Option, Box, Typography } from '@mui/joy';

const EnemiesList = ({ currentUser, sort }) => {
    const sortOptions = [
        { label: 'Top', value: 'top', link: '/top/' },
        { label: 'New', value: 'new', link: '/new/' },
        { label: 'Bottom', value: 'bottom', link: '/bottom/' },
        { label: 'Old', value: 'old', link: '/old/' },
    ];

    const [enemies, setEnemies] = useState([]);
    const [error, setError] = useState(null);
    const [filterScope, setFilterScope] = useState('All');
    const [filterStatus, setFilterStatus] = useState('All');
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1); // For pagination
    const [hasMore, setHasMore] = useState(true);

    // Determine sortField and sortOrder based on the "sort" parameter
    useEffect(() => {
        switch (sort) {
            case 'top':
                setSortField('vote_count');
                setSortOrder('desc');
                break;
            case 'bottom':
                setSortField('vote_count');
                setSortOrder('asc');
                break;
            case 'new':
                setSortField('created_ts');
                setSortOrder('desc');
                break;
            case 'old':
                setSortField('created_ts');
                setSortOrder('asc');
                break;
            default:
                setSortField('vote_count');
                setSortOrder('desc');
                break;
        }
    }, [sort]);

    // Fetch enemies only when sortField and sortOrder are set
    useEffect(() => {
        if (sortField && sortOrder) {
            setLoading(true);
            setEnemies([]); // Clear the current list when sorting changes
            setPage(1); // Reset to first page

            fetchEnemies(1); // Fetch the first page with new sorting
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortField, sortOrder]);

    const fetchEnemies = async (page) => {
        try {
            const response = await api.get('/api/entities', {
                params: {
                    entityType: 'enemy',
                    sortField: sortField,
                    sortOrder: sortOrder,
                    limit: 10,
                    offset: (page - 1) * 10, // Pagination logic
                },
            });

            if (response.data.length < 10) {
                setHasMore(false); // If fewer than 10 entities are fetched, there are no more pages
            }

            setEnemies((prevEnemies) => {
                const enem = response.data.map((enemy) => enemy.attributes);
                const newEnemies = enem.filter(
                    (newEnemy) => !prevEnemies.some((enemy) => enemy.id === newEnemy.id)
                );
                return [...prevEnemies, ...newEnemies];
            });
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch enemies');
            setLoading(false);
        }
    };

    // Fetch more enemies as the user scrolls down
    useEffect(() => {
        if (!loading) {
            fetchEnemies(page);
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handleScroll = (e) => {
        if (loading || !hasMore) return;

        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, hasMore]);

    const handleScopeChange = (e, newValue) => setFilterScope(newValue);
    const handleStatusChange = (e, newValue) => setFilterStatus(newValue);

    const handleSortChange = (e, newValue) => {
        const selectedOption = sortOptions.find((option) => option.value === newValue);
        if (selectedOption) {
            window.location.href = selectedOption.link; // Update the URL to match the selected sort option
        }
    };

    const filteredEnemies = enemies.filter((enemy) => {
        return (
            (filterScope === 'All' || enemy.scope === filterScope) &&
            (filterStatus === 'All' || enemy.status === filterStatus)
        );
    });

    if (loading && page === 1) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', pb: 1, mb: 1, borderBottom: '0.05rem solid #c8c8c8' }}>
                <Select value={sort || 'top'} onChange={handleSortChange}>
                    {sortOptions.map((sortButton) => (
                        <Option key={sortButton.value} value={sortButton.value}>
                            <Box sx={{ display: 'flex', alignItems: 'left' }}>
                                <Typography textAlign="left" sx={{ ml: 1 }}>
                                    {sortButton.label}
                                </Typography>
                            </Box>
                        </Option>
                    ))}
                </Select>
                
                <Select value={filterScope} onChange={handleScopeChange}>
                    <Option value="All">Any Scope</Option>
                    <Option value="Local">Local</Option>
                    <Option value="National">National</Option>
                    <Option value="Global">Global</Option>
                </Select>
                
                <Select value={filterStatus} onChange={handleStatusChange}>
                    <Option value="All">Any Status</Option>
                    <Option value="Emerging">Emerging</Option>
                    <Option value="Established">Established</Option>
                    <Option value="Eroding">Eroding</Option>
                    <Option value="Eliminated">Eliminated</Option>
                </Select>
            </Box>
            {filteredEnemies.map((enemy) => (
                <EnemyCard key={enemy.id} enemy={enemy} showMeta={true} showActions={true} onVote={() => {}} truncateDescription={true} currentUser={currentUser}/>
            ))}
        </div>
    );
};

export default EnemiesList;
