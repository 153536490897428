import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EntityActions from './EntityActions';
import api from '../config/axiosConfig';
import { Menu, MenuItem, IconButton, Modal, ModalDialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/joy';
import { ReactComponent as MoreIcon } from '../assets/button-card-actions.svg';
import { ReactComponent as EditIcon } from '../assets/button-edit.svg';
import { ReactComponent as ReportIcon } from '../assets/button-report.svg';
import { ReactComponent as DeleteIcon } from '../assets/icon-delete.svg';
import './EntityCard.css';

const ImpactCard = ({ impact, showMeta = true, truncateDescription = true, isImpactProfilePage = false, currentUser }) => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const impactHref = `/impact/${encodeURIComponent(impact.id)}`;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const moreIconRef = useRef(null);

    const truncateText = (text, length) => {
        if (!text || text.length <= length) return text;
        return text.substring(0, length) + '...';
    };

    const handleCardClick = (e) => {
        // Prevent navigation when clicking on the reference link
        if (e.target.tagName.toLowerCase() === 'a') return;
        navigate(impactHref);
    };

    const handleDescriptionClick = (e) => {
        if (!truncateDescription) return;
        e.preventDefault();
        if (isImpactProfilePage) {
            setIsExpanded(!isExpanded);
        } else {
            return;
        }
    };

    const handleMenuToggle = (event) => {
        if (anchorEl) {
            setAnchorEl(null); // Close the menu if it's already open
        } else {
            setAnchorEl(event.currentTarget); // Open the menu
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    
    const handleEdit = () => {
        navigate(`/impact/${impact.id}?edit=true`);
    };

    const handleDelete = async () => {
        try {
            await api.delete(`/api/impacts/id/${impact.id}`);
            setIsDeleteDialogOpen(false);
            if (isImpactProfilePage) {
                navigate('/');
            } else {
            window.location.reload();
            }
        } catch (error) {
            console.error('Error deleting Impact:', error);
        }
    };

    const openDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
    };
    
    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

     // Close the menu if clicked outside
     useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchorEl && !moreIconRef.current.contains(event.target)) {
                handleMenuClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [anchorEl]);

    return (
        <div className="container">
        <div className="card">
            <div className="entity-actions-container">
                <EntityActions entity={impact} entityType="impact" currentUser={currentUser}/>
            </div>
            <div className="card-content" onClick={handleCardClick}>
            <div className="card-header">
                    <Link to={`/impact/${impact.id}`} className="card-title">
                        {impact.title}
                    </Link>
                <div className="card-tags">
                    {impact.scope && <span className="tag scope">{impact.scope}</span>}
                </div>
            </div>
            <div className="card-description" onClick={handleDescriptionClick}>
            <p>
                {truncateDescription && !isExpanded ? truncateText(impact.description || '', 512) : impact.description}
            </p>
            </div>
            {impact.reference_link && (
                <a href={impact.reference_link} target="_blank" rel="noopener noreferrer" className="card-link">
                    {truncateText(impact.reference_link || '', 64)}
                </a>
            )}
            </div>
            {showMeta && (
                <div className="card-meta">
                    <Link to={`/user/${impact.username}`} className="username">{impact.username}</Link>
                    <span>{impact.updated_ts ? new Date(impact.updated_ts).toLocaleString() : 'No update date'}</span>
                    <div className="icon-more"><IconButton onClick={handleMenuToggle} >
                            <MoreIcon />
                        </IconButton></div>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        ref={moreIconRef}
                        >
                        <div className="icon-edit">{currentUser?.id === impact.user_id && (
                            <>
                            <MenuItem onClick={handleEdit}>
                               <button> <EditIcon /> </button>
                            </MenuItem>
                            <div className="icon-delete">
                            <MenuItem onClick={openDeleteDialog}>
                                <button><DeleteIcon /> </button>
                            </MenuItem>
                            </div>
                            </>
                        )}</div>
                        <div className="icon-report"><MenuItem onClick={handleMenuClose}>
                            <button> <ReportIcon /></button>
                            </MenuItem></div>
                    </Menu> 
                </div>
            )}
            </div>
            {/* Confirmation Dialog */}
        <Modal
            open={isDeleteDialogOpen}
            onClose={closeDeleteDialog}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
        >
            <ModalDialog>
            <DialogTitle id="delete-dialog-title">{"Delete Impact"}</DialogTitle>
            <DialogContent>
                    Are you sure you want to delete this Impact?
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDeleteDialog} color="primary">
                    No
                </Button>
                <Button onClick={handleDelete} color="primary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
            </ModalDialog>
        </Modal>
        </div>
    );
};

export default ImpactCard;