import React, { useState, useEffect } from 'react';
import { Tabs, Tab, TabList, TabPanel, Box, Sheet, Container } from '@mui/joy';
import { useParams, Link } from 'react-router-dom';
import api from '../config/axiosConfig';
import UserProfileOverview from './UserProfileOverview';
import UserProfileEntities from './UserProfileEntities';
import UserProfileComments from './UserProfileComments';

const UserProfile = ({ currentUser }) => {
    const { username, tab } = useParams();
    const [selectedTab, setSelectedTab] = useState(tab || 'overview');
    const [usernameActive, setUsernameActive] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchUsername = async () => {
            try {
                const response = await api.get(`/api/user/${username}`);
                setUsernameActive(response.data);
            } catch (error) {                
                console.error(`Error fetching user with username: ${username}`, error);
                setError(`User with username ${username} does not exist.`);
            } 
        };

        fetchUsername();
    }, [username]);


    const handleTabChange = (e, newValue) => {
        setSelectedTab(newValue);
        if (newValue === 'overview') {
            window.location.href = `/user/${username}`;
        } else {
        window.location.href = `/user/${username}/${newValue}`;
        }
    };

    if (!usernameActive) {
        return <Sheet>{error}</Sheet>;
    }

    return (
        <Container>
        <Box>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="User Profile Tabs"
                size="lg"
                sx={{
                    '--Tabs-gap': '8px',
                    '--Tabs-radius': '12px',
                    '--Tabs-color': 'neutral.outlinedActiveBg', marginTop: '6rem'
                }}
            >
                <TabList 
                    sticky='top'
                    sx={{ backgroundColor: 'background.surface', borderRadius: '8px', p: 1}}>
                    <Tab
                        component={Link}
                        to={`/user/${username}`}
                        value="overview"
                        disableIndicator={true}
                        sx={{
                            borderRadius: '12px',
                            textDecoration: selectedTab === 'overview' ? 'underline' : 'none',
                            '&:hover': { textDecoration: 'underline', backgroundColor: 'neutral.200' },
                        }}
                    >
                        Overview
                    </Tab>
                    <Tab
                        component={Link}
                        to={`/user/${username}/entities`}
                        value="entities"
                        disableIndicator={true}
                        sx={{
                            borderRadius: '12px',
                            textDecoration: selectedTab === 'entities' ? 'underline' : 'none',
                            '&:hover': { textDecoration: 'underline', backgroundColor: 'neutral.200' },
                        }}
                    >
                        Entities
                    </Tab>
                    <Tab
                        component={Link}
                        to={`/user/${username}/comments`}
                        value="comments"
                        disableIndicator={true}
                        sx={{
                            borderRadius: '12px',
                            textDecoration: selectedTab === 'comments' ? 'underline' : 'none',
                            '&:hover': { textDecoration: 'underline', backgroundColor: 'neutral.200' },
                        }}
                    >
                        Comments
                    </Tab>
                </TabList>
                <TabPanel value="overview">
                    <UserProfileOverview username={username} currentUser={currentUser} />
                </TabPanel>
                <TabPanel value="entities">
                    <UserProfileEntities username={username} currentUser={currentUser} />
                </TabPanel>
                <TabPanel value="comments">
                    <UserProfileComments username={username} currentUser={currentUser} />
                </TabPanel>
            </Tabs>
        </Box>
        </Container>
    );
};

export default UserProfile;