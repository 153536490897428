import React, { useState, useEffect } from 'react';
import { CommentSection } from 'react-comments-section';
import 'react-comments-section/dist/index.css';
import api from '../config/axiosConfig';
import './CommentsSection.css';

const CommentsSection = ({ commentableType, commentableId, currentUser }) => {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [commentUser, setCommentUser] = useState(null);

    
    useEffect(() => {
        const fetchComments = async () => {
            if (currentUser) {
                setCommentUser({
                    currentUserId: currentUser.id,
                    currentUserImg: currentUser.avatarUrl || '',
                    currentUserFullName: currentUser.username
                });
            }
        
            try {
                const response = await api.get(`/api/comments/${commentableType}/${commentableId}`);
                setComments(response.data.map(comment => ({
                    userId: comment.user_id,
                    comId: comment.id.toString(),
                    fullName: comment.username,
                    avatarUrl: comment.avatarUrl || '',
                    text: comment.content,
                    replies: comment.replies ? comment.replies.map(reply => ({
                        userId: reply.user_id,
                        comId: reply.id.toString(),
                        parentId: reply.parent_id ? reply.parent_id.toString() : null,
                        rootId: reply.root_id ? reply.root_id.toString() : null,
                        fullName: reply.username,
                        avatarUrl: reply.avatarUrl || '',
                        text: reply.content,
                        userProfile: `/user/${reply.username}`
                    })) : []
                })));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching comments:', error);
                setLoading(false);
            }
        };

        fetchComments();
    }, [commentableType, commentableId, currentUser]);

    const handleCommentSubmit = async (data) => {
        try {
            const response = await api.post(`/api/comments/${commentableType}/${commentableId}`, {
                id: data.comId,
                parent_id: data.parentId ? data.parentId : null,
                content: data.text
            });
            setComments([...comments, {
                userId: commentUser.id,
                comId: response.data.id.toString(),
                fullName: commentUser.currentUserFullName,
                avatarUrl: commentUser.currentUserImg,
                text: data.text,
                replies: []
            }]);
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    const handleCommentEdit = async (data) => {
        try {
            await api.put(`/api/comments/${data.comId}`, {
                content: data.text
            });
            setComments(comments.map(comment => comment.comId === data.comId ? { ...comment, text: data.text } : comment));
        } catch (error) {
            console.error('Error editing comment:', error);
        }
    };

    const handleCommentDelete = async (data) => {
        try {
            await api.delete(`/api/comments/${data.comId}`);
            setComments(comments.map(comment => comment.comId === data.comId ? { ...comment, text: '[deleted]' } : comment));
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const handleReplyComment = async (data) => {
        try {
            await api.post(`/api/comments/${commentableType}/${commentableId}`, {
                id: data.comId,
                content: data.text,
                parent_id: data.repliedToCommentId ? data.repliedToCommentId : null
            });                
        } catch (error) {
            console.error('Error replying to comment:', error);
        }
    };

    return (
        <div className="comments-section">
            <div className="comments-card">
            {loading ? (
                <p>Loading comments...</p>
            ) : (
                <CommentSection
                    currentUser={commentUser}
                    commentData={comments}
                    onSubmitAction={handleCommentSubmit}
                    onEditAction={handleCommentEdit}
                    onDeleteAction={handleCommentDelete}
                    onReplyAction={handleReplyComment}
                    logIn={{
                        loginLink: '/login',
                        signupLink: '/login'
                      }}
                />
            )}
            </div>
        </div>
    );
};

export default CommentsSection;