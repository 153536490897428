import React, { useState, useEffect } from 'react';
import { Modal, ModalDialog, DialogContent, FormControl, FormLabel, DialogTitle, Input, Button, FormHelperText, Stack } from '@mui/joy';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import api from '../config/axiosConfig';

const UsernameModal = ({ onClose, currentUser }) => {
    const [username, setUsername] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    // Function to validate username with additional constraints
    const validateUsername = async (username) => {
        if (
            !/^[a-z0-9_.]{2,30}$/.test(username) || // Valid characters and length
            /\.{2,}/.test(username) || // No consecutive dots
            /^admin/.test(username) || // Cannot start with "admin"
            /admin$/.test(username) || // Cannot end with "admin"
            username === 'user' || // Cannot be "user"
            username.includes('enemiesofearth') // Cannot contain "enemiesofearth"
        ) {
            setIsValid(false);
            setValidationMessage('Invalid or restricted username format.');
            return;
        }

        try {
            const response = await api.get(`/api/user/${username}`);
            if (response.status === 200) {
                setIsValid(false);
                setValidationMessage('Username already taken.');
            } else {
                setIsValid(true);
                setValidationMessage('Username is available.');
            }
        } catch (error) {
            setIsValid(true);
            setValidationMessage('Username is available.');
        }
    };

    useEffect(() => {
        if (username) {
            validateUsername(username);
        } else {
            setIsValid(false);
            setValidationMessage('');
        }
    }, [username]);

    const handleSubmit = async () => {
        if (isValid) {
            try {
                await api.patch('/api/user/username', { username });
                window.location.reload(); // Reload the page after username update
            } catch (error) {
                console.error('Error updating username:', error);
                setValidationMessage('Failed to update username. Please try again.');
            }
        }
    };

    const handleClose = () => {
        onClose();
        window.location.reload();
    };

    const handleInputChange = (e) => {
        const lowercaseUsername = e.target.value.toLowerCase(); // Convert input to lowercase
        setUsername(lowercaseUsername);
    };

    return (
        <Modal open onClose={handleClose}>
            <ModalDialog size="lg">
                <DialogTitle>Set your Username</DialogTitle>
                <DialogContent>Usernames are publicly visible.</DialogContent>
                <FormControl>
                    <FormLabel>Username</FormLabel>
                    <Input
                        label="Username"
                        value={username}
                        placeholder={currentUser?.username}
                        onChange={handleInputChange}
                        fullWidth
                        error={!isValid && username.length > 0}
                        endDecorator={
                            <CheckCircleOutlined sx={{ color: isValid ? 'green' : 'default' }} />
                        }
                    />
                    {!isValid && username.length > 0 && (
                        <FormHelperText color="danger">
                            <InfoOutlined /> {validationMessage}
                        </FormHelperText>
                    )}
                </FormControl>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button variant="plain" onClick={onClose}>Cancel</Button>
                <Button
                    onClick={handleSubmit}
                    disabled={!isValid}
                    type="submit"
                >
                    Submit
                </Button>
                </Stack>
            </ModalDialog>
        </Modal>
    );
};

export default UsernameModal;