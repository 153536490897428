import React, { useState } from 'react';
import Vote from './Vote';
import {Chip} from '@mui/joy';
import './EntityActions.css';

import { ReactComponent as ShareIcon } from '../assets/button-share.svg';
import { ReactComponent as ShareClipIcon } from '../assets/button-share-clip.svg';
import { ReactComponent as CommentIcon } from '../assets/button-comment.svg';

const EntityActions = ({ entity, entityType, onVote, currentUser }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [copied, setCopied] = useState(false);
  
  const url = entityType === 'enemy' 
  ? `${window.location.origin}/${entityType}/${entity.name.replace(/ /g, '_')}`
  : `${window.location.origin}/${entityType}/${entity.id}`;

  const handleShare = () => {      
    navigator.clipboard.writeText(url)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
  };

  const handleComment = () => {
    if (window.location.href === url) {
    const commentsSection = document.getElementsByClassName('comment-title')[0];
    if (commentsSection) {
      commentsSection.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
        }
    } else {
    window.location.href = url;
    };
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getChipColor = (entityType, entity) => {
    if (entityType === 'impact') {
      return entity.rel_type === 'impact_positive' ? '#419357' : '#df7023';
    }
    switch (entityType) {
      case 'enemy':
        return '#cf1f1f';
      case 'solution':
        return '#005286';
      default:
        return '#555555'; // Fallback color
    }
  };

  return (
    <div className="entity-actions">            
    <Chip
            label={capitalizeFirstLetter(entityType)}
            color="neutral"
            variant="soft"
            size="lg"
            sx={{mt: .8,
              backgroundColor: getChipColor(entityType, entity),
              color: '#fff',}}
        >
        {capitalizeFirstLetter(entityType)}
        </Chip>
    <Vote entityId={entity.id} onVote={onVote} voteCount={entity.vote_count} entityType={entityType} currentUser={currentUser}/>
      <div className="action comment" onClick={handleComment}>
        <CommentIcon />
      </div>
      {copied && <div className="copied-banner">
        <p>Copied!</p>
        </div>}
      <div className="action share" onClick={() => setShowShareOptions(!showShareOptions)}>
        <ShareIcon />
        {showShareOptions && (
          <div className="share-options">
            <div className="option" onClick={handleShare}>
            <ShareClipIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EntityActions;
