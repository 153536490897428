import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, CircularProgress } from '@mui/joy';
import api from '../config/axiosConfig';
import EnemyCard from './EnemyCard';
import ImpactCard from './ImpactCard';
import SolutionCard from './SolutionCard';

const UserProfileEntities = ({ username, currentUser }) => {
    const [entities, setEntities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1); // For pagination
    const [hasMore, setHasMore] = useState(true);

    // Fetch entities from the backend
    const fetchUserEntities = async (username, page) => {
        try {
            const response = await api.get(`/api/entities`, {
                params: {
                    username,
                    limit: 5,
                    offset: (page - 1) * 5, // Pagination logic
                },
            });

            if (response.data.length < 5) {
                setHasMore(false); // If less than 10 entities are fetched, there are no more pages
            }

            setEntities(prevEntities => {
                const newEntities = response.data.filter(
                    newEntity => !prevEntities.some(entity => entity.eid === newEntity.eid)
                );
                return [...prevEntities, ...newEntities];
            });
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch entities');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserEntities(username, page);
    }, [username, page]);

    const handleScroll = (e) => {
        if (loading || !hasMore) return;

        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
            setPage(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, hasMore]);

    const renderEntityCard = (entity) => {
        const { entity_type, attributes} = entity;

        switch (entity_type) {
            case 'enemy':
                return <EnemyCard key={entity.id} enemy={attributes} showMeta={true} showActions={true} truncateDescription={true} isEnemyProfilePage={false} currentUser={currentUser} isEntityPage={true}/>;
            case 'impact':
                return <ImpactCard key={entity.id} impact={attributes} showMeta={true} showActions={true} truncateDescription={true} isImpactProfilePage={false} currentUser={currentUser} isEntityPage={true}/>;
            case 'solution':
                return <SolutionCard key={entity.id} solution={attributes} showMeta={true} showActions={true} truncateDescription={true} isSolutionProfilePage={false} currentUser={currentUser} isEntityPage={true}/>;
            default:
                return null;
        }
    };

    if (loading && page === 1) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body2" color="error">{error}</Typography>;
    }

    return (
        <Box sx={{ my: 2 }}>
            {/* Sort & Filter Bar - currently just sort by 'New' */}
            <Box sx={{ mb: 2 }}>
                <Typography variant="body2">Sort by: New</Typography>
            </Box>

            <Grid container spacing={1}>
                {entities.map((entity) => (
                    <Grid xs={12} key={entity.eid}>
                        {renderEntityCard(entity)}
                    </Grid>
                ))}
            </Grid>

            {loading && <CircularProgress />}
        </Box>
    );
};

export default UserProfileEntities;