import React, { useEffect } from 'react';
import api from '../config/axiosConfig';

const AuthLogout = () => {
    useEffect(() => {
        const logout = async () => {
            const response = await api.get(`${process.env.REACT_APP_API_URL}/auth/logout`);
            if (response.status === 200) {
                window.location.href = '/';
            } else {
                console.error('Error logging out:', response);
                window.location.href = '/';
            }
        };
        
        logout();            
    }, []);

    return (
        <div className="auth-logout">
            <p>Logging you out...</p>
        </div>
    );
};

export default AuthLogout;