import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, Stack, FormControl, FormLabel } from '@mui/joy';
import UsernameModal from './UsernameModal';
import ArrowForward from '@mui/icons-material/ArrowForward';

const UserSettingsProfile = ({ currentUser, awaitingUser }) => {
    const navigate = useNavigate();
    const [showUsernameModal, setShowUsernameModal] = useState(false);

    useEffect(() => {
        if (!awaitingUser && !currentUser) {
            navigate('/login', { replace: true });
        } 
    }, [currentUser, awaitingUser, navigate]);

    return (
        <Container>
            <Stack direction="column" spacing={2}>
                <FormControl>
                    <FormLabel>General</FormLabel>
                    <Stack direction="row" alignItems="center" spacing={1} onClick={() => setShowUsernameModal(true)} sx={{ cursor: 'pointer' }}>
                        <Typography>Username</Typography>
                        <ArrowForward />
                    </Stack>
                </FormControl>
            </Stack>
            {showUsernameModal && <UsernameModal onClose={() => setShowUsernameModal(false)} currentUser={currentUser} />}            
        </Container>
    );
};

export default UserSettingsProfile;