import React, { useEffect, useState } from 'react';
import api from '../config/axiosConfig';
import { useParams, useSearchParams } from 'react-router-dom';
import EnemyCard from './EnemyCard';
import ImpactCard from './ImpactCard';
import ImpactEditor from './ImpactEditor';
import CommentsSection from './CommentsSection';
import { CircularProgress } from '@mui/joy';
import './ImpactProfile.css';

const ImpactProfile = ({ currentUser }) => {
    const { id } = useParams();
    const [impact, setImpact] = useState(null);
    const [relatedEnemy, setRelatedEnemy] = useState(null);
    const [error, setError] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [description, setDescription] = useState('');
    const [scope, setScope] = useState('');
    const [referenceLink, setReferenceLink] = useState('');
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const fetchImpact = async () => {
            try {
                const response = await api.get(`/api/impacts/${id}`);
                setImpact(response.data);
                setDescription(response.data.description);
                setScope(response.data.scope);
                setReferenceLink(response.data.reference_link);
                if (response.data.enemy_id) {
                    const enemyResponse = await api.get(`/api/enemies/id/${response.data.enemy_id}`);
                    setRelatedEnemy(enemyResponse.data);
                } if (searchParams.get('edit') === 'true') {
                    setEditMode(true);
                }
            } catch (err) {
                setError('Failed to load impact profile. Please try again later.');
            }
        };

        fetchImpact();
    }, [id, searchParams]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!impact) {
        return <div><CircularProgress size="lg"/></div>;
    }

    return (
        <div className="impact-profile">
            {impact && (
        <>
          {editMode ? (
            <ImpactEditor impact={impact} currentUser={currentUser} description={description} scope={scope} referenceLink={referenceLink} editMode={true} />
          ) : (
            <ImpactCard impact={impact} truncateDescription={true} isImpactProfilePage={true} currentUser={currentUser}/>
        )}
        </>
      )}
            <CommentsSection commentableId={impact.id} commentableType="impact" currentUser={currentUser} />
            <div className="related-enemies-container">
                <h2>Related Enemy</h2>
                {relatedEnemy && <EnemyCard enemy={relatedEnemy} showMeta={true} showActions={true} onVote={() => {}} truncateDescription={true} currentUser={currentUser}/>}
            </div>
        </div>
    );
};

export default ImpactProfile;
