import React, { useEffect, useState } from 'react';
import api from '../config/axiosConfig';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import EnemyCard from './EnemyCard';
import EnemyEditor from './EnemyEditor';
import { Box, Container, CircularProgress } from '@mui/joy';
import EnemyProfileTabs from './EnemyProfileTabs';
import './EnemyProfile.css';

const EnemyProfile = ({ currentUser, showAuthModal }) => {
    const { name } = useParams();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [enemy, setEnemy] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [description, setDescription] = useState('');
    const [scope, setScope] = useState(false);
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchEnemy = async () => {
            try {
                const response = await api.get(`/api/enemies/name/${name}`);
                const enemyData = response.data;
                const params = new URLSearchParams(location.search);
                if (params.get('upvoted') === 'true') {
                    enemyData.user_vote = 'user_upvote';
                }
                if (searchParams.get('edit') === 'true') {
                    setEditMode(true);
                }            
                setEnemy(enemyData);
                setDescription(enemyData.description);
                setScope(enemyData.scope);
                setStatus(enemyData.status);
            } catch (err) {
                setError('Failed to load enemy profile. Please try again later.');
            }
        };

        fetchEnemy();
    }, [name, location.search, searchParams]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!enemy) {
        return <div><CircularProgress size="lg"/></div>;
    }

    return (
        <Box>
            <Container> 
                {enemy && (
                <>
                {editMode ? (
                    <EnemyEditor enemy={enemy} currentUser={currentUser} description={description} status={status} scope={scope} editMode={true} />
                ) : (
                        <EnemyCard enemy={enemy} showMeta={true} showActions={true} truncateDescription={true} isEnemyProfilePage={true} onVote={() => {}} currentUser={currentUser}/>
                    )}
                </>
                )}
            </Container>
            <EnemyProfileTabs enemy={enemy} currentUser={currentUser} showAuthModal={showAuthModal} />
        </Box>
    );
};

export default EnemyProfile;