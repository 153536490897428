import React, { useEffect, useState } from 'react';
import api from '../config/axiosConfig';
import { useParams, useSearchParams } from 'react-router-dom';
import EnemyCard from './EnemyCard';
import SolutionCard from './SolutionCard';
import AddEnemyToSolution from './AddEnemyToSolution';
import CommentsSection from './CommentsSection';
import SolutionEditor from './SolutionEditor';
import { CircularProgress } from '@mui/joy';
import './SolutionProfile.css';

const SolutionProfile = ({ currentUser }) => {
    const { id } = useParams();
    const [solution, setSolution] = useState(null);
    const [relatedEnemies, setRelatedEnemies] = useState([]);
    const [error, setError] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [description, setDescription] = useState('');
    const [referenceLink, setReferenceLink] = useState('');
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const fetchSolution = async () => {
            try {
                const response = await api.get(`/api/solutions/id/${id}`);
                setSolution(response.data);
                setDescription(response.data.description);
                setReferenceLink(response.data.reference_link);
                if (searchParams.get('edit') === 'true') {
                    setEditMode(true);
                  }
            } catch (err) {
                setError('Failed to load solution profile. Please try again later.');
            }
        };

        const fetchRelatedEnemies = async () => {
            try {
                const response = await api.get(`/api/solutions/${id}/enemies`);
                const enemyIds = response.data.map(enemy => enemy.enemy_id);
                const enemiesResponse = await api.get(`/api/enemies?enemyIds=${JSON.stringify(enemyIds)}`);
                setRelatedEnemies(enemiesResponse.data);
            } catch (err) {
                setError('Failed to load related enemies. Please try again later.');
            }
        };

        fetchSolution();
        fetchRelatedEnemies();
    }, [id, searchParams]);

    const handleEnemyAdded = (newEnemy) => {
        setRelatedEnemies((prevEnemies) => [...prevEnemies, newEnemy]);
    };
    
    if (error) {
        return <div>{error}</div>;
    }

    if (!solution) {
        return <div><CircularProgress size="lg"/></div>;
    }

    return (
        <div className="solution-profile">
            {solution && (
        <>
          {editMode ? (
            <SolutionEditor solution={solution} currentUser={currentUser} description={description} referenceLink={referenceLink} editMode={true} />
          ) : (
            <SolutionCard solution={solution} isSolutionProfilePage={true} currentUser={currentUser}/>
        )}
        </>
      )}
            <CommentsSection commentableId={solution.id} commentableType="solution" currentUser={currentUser} />
            <div className="related-enemies-container">
                <h2>Related Enemies</h2>
                <AddEnemyToSolution solutionId={id} onEnemyAdded={handleEnemyAdded} />
                {relatedEnemies.map((enemy) => (
                    <EnemyCard key={enemy.id} enemy={enemy} showMeta={true} showActions={true} onVote={() => {}} truncateDescription={true} currentUser={currentUser}/>
                ))}
            </div>
        </div>
    );
};

export default SolutionProfile;