import React, { useState } from 'react';
import { Modal, ModalDialog, Button, FormControl, FormLabel, Input, Textarea, FormHelperText } from '@mui/joy';
import api from '../config/axiosConfig';
import AuthModal from './AuthModal';

const CreateSolution = ({ enemyId, onSolutionAdded, currentUser, open, onClose }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [referenceLink, setReferenceLink] = useState('');
    const [error, setError] = useState('');
    const [showAuthModal, setShowAuthModal] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!currentUser) {
            setShowAuthModal(true);
            return;
        }

        try {
            const response = await api.post(`/api/solutions/${enemyId}`, {
                name,
                description,
                reference_link: referenceLink,
                user_id: currentUser.id
            });

            const newSolution = response.data;

            // Automatically upvote the solution
            await api.post(`/api/votes/solution/${newSolution.id}`, {
                user_id: currentUser.id,
                rel_type: 'user_upvote'
            });

            onSolutionAdded(newSolution);
            setName('');
            setDescription('');
            setReferenceLink('');
            setError('');
            onClose(); // Close the modal after successful submission
        } catch (error) {
            console.error('Error creating solution:', error);
            setError('Failed to create solution. Please try again later.');
        }
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <ModalDialog>
                    <form onSubmit={handleSubmit}>
                        <FormControl required>
                            <FormLabel>Name</FormLabel>
                            <Input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                maxLength="200"
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                maxLength="4000"
                                minRows={5}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Reference Link</FormLabel>
                            <Input
                                type="url"
                                value={referenceLink}
                                onChange={(e) => setReferenceLink(e.target.value)}
                            />
                        </FormControl>
                        {error && <FormHelperText color="danger">{error}</FormHelperText>}
                        <Button type="submit" sx={{ mt: 2 }}>Submit</Button>
                    </form>
                </ModalDialog>
            </Modal>
            {showAuthModal && <AuthModal show={showAuthModal} onClose={() => setShowAuthModal(false)} />}
        </>
    );
};

export default CreateSolution;
