import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, Stack, Modal, ModalDialog, DialogTitle, DialogContent, FormControl, FormLabel, Textarea, Input, Button, FormHelperText } from '@mui/joy';
import ArrowForward from '@mui/icons-material/ArrowForward';
import api from '../config/axiosConfig';

const UserSettingsAccount = ({ currentUser, awaitingUser }) => {
    const navigate = useNavigate();
    const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [email, setEmail] = useState('');
    const [reason, setReason] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);

    useEffect(() => {
        if (!awaitingUser && !currentUser) {
            navigate('/login', { replace: true });
        } 
    }, [currentUser, awaitingUser, navigate]);

    const handleEmailConfirm = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue));
        setErrorMessage('');
    };

    const handleDeactivate = async () => {
        if (!isEmailValid || email !== currentUser.email) {
            setErrorMessage('The provided email is not associated with this account.');
            return;
        }
        try {
            await api.post('/api/user/deactivate', { username: currentUser.username, reason });
            window.location.href = '/logout';
        } catch (error) {
            console.error('Error deactivating account:', error);
        }
    };

    const handleDelete = async () => {
        if (!isEmailValid || email !== currentUser.email) {
            setErrorMessage('The provided email is not associated with this account.');
            return;
        }
        setOpenDeleteModal(false);
        if (window.confirm('Are you sure? Your profile and username will be permanently removed.')) {
            try {
                await api.delete('/api/user/delete', { data: { username: currentUser.username, reason } });
                window.location.href = '/logout';
            } catch (error) {
                console.error('Error deleting account:', error);
            }
        }
    };

    return (
        <Container>
            <Stack direction="column" spacing={2}>
                <FormControl>
                    <FormLabel>Advanced</FormLabel>
                    <Stack direction="row" alignItems="center" spacing={1} onClick={() => setOpenDeactivateModal(true)} sx={{ cursor: 'pointer' }}>
                        <Typography>Deactivate</Typography>
                        <ArrowForward />
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1} onClick={() => setOpenDeleteModal(true)} sx={{ cursor: 'pointer' }}>
                        <Typography>Delete Account</Typography>
                        <ArrowForward />
                    </Stack>
                </FormControl>
            </Stack>
            
            {/* Deactivate Modal */}
            <Modal open={openDeactivateModal} onClose={() => setOpenDeactivateModal(false)}>
                <ModalDialog>
                    <DialogTitle>Deactivate account</DialogTitle>
                    <DialogContent>We understand that you need a break. If you login after deactivating your account, your account will automatically be reactivated.</DialogContent>
                        <FormControl>
                            <FormLabel>Reason</FormLabel>
                            <Textarea placeholder="Reason for deactivation (optional)" onChange={(e) => setReason(e.target.value)} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Email address</FormLabel>
                            <Input placeholder="Confirm email address" onChange={handleEmailConfirm} />
                            {errorMessage && <FormHelperText color="danger">{errorMessage}</FormHelperText>}
                        </FormControl>
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                            <Button variant="plain" onClick={() => setOpenDeactivateModal(false)}>Cancel</Button>
                            <Button disabled={!isEmailValid} onClick={handleDeactivate}>Submit</Button>
                        </Stack>
                </ModalDialog>
            </Modal>

            {/* Delete Modal */}
            <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <ModalDialog>
                    <DialogTitle>Delete account</DialogTitle>
                    <DialogContent>
                        <Typography>We're sad that you're leaving the fight.</Typography>
                        <FormControl>
                            <FormLabel>Reason</FormLabel>
                            <Textarea placeholder="Reason for leaving (optional)" onChange={(e) => setReason(e.target.value)} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Email address</FormLabel>
                            <Input placeholder="Confirm email address" onChange={handleEmailConfirm} />
                            {errorMessage && <FormHelperText color="danger">{errorMessage}</FormHelperText>}
                        </FormControl>
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                            <Button variant="plain" onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
                            <Button disabled={!isEmailValid} onClick={handleDelete}>Submit</Button>
                        </Stack>
                    </DialogContent>
                </ModalDialog>
            </Modal>
        </Container>
    );
};

export default UserSettingsAccount;