import React, { useState, useEffect } from 'react';
import api from '../config/axiosConfig';
import AuthModal from './AuthModal';

import { ReactComponent as UpvoteIcon } from '../assets/button-upvote.svg';
import { ReactComponent as DownvoteIcon } from '../assets/button-downvote.svg';

const Vote = ({ entityId, onVote, voteCount, entityType, currentUser }) => {
    const [upvotes, setUpvotes] = useState(0);
    const [downvotes, setDownvotes] = useState(0);
    const [entityVoteCount, setVoteCount] = useState(voteCount);
    const [userVote, setUserVote] = useState(null);
    const [showAuthModal, setShowAuthModal] = useState(false);

    useEffect(() => {
        const fetchVotes = async () => {
            try {
                if (currentUser) {
                    const response = await api.get(`/api/votes/user/${entityType}/${entityId}`);
                    setUserVote(response.data)
                }
            } catch (error) {
                console.error('Error fetching votes:', error);
            }
        };

        fetchVotes();
    }, [entityId, entityType, currentUser]);

    const handleVote = async (type) => {
        if (!currentUser) {
            setShowAuthModal(true);
            return;
        }

        try {
            let newVoteType = type;
            if (userVote === type) {
                newVoteType = 'user_unvote';
            }

            const voteData = {
                rel_type: newVoteType,
                user_id: currentUser.id // Pass the user_id to the backend
            };

            await api.post(`/api/votes/${entityType}/${entityId}`, voteData);

            if (newVoteType === 'user_upvote') {
                setUpvotes(upvotes + 1);
                if (userVote === 'user_downvote') {
                    setDownvotes(downvotes - 1);
                    setVoteCount(entityVoteCount + 2);
                } else {
                    setVoteCount(entityVoteCount + 1);
                }
            } else if (newVoteType === 'user_downvote') {
                setDownvotes(downvotes + 1);
                if (userVote === 'user_upvote') {
                    setUpvotes(upvotes - 1);
                    setVoteCount(entityVoteCount - 2);
                } else {
                    setVoteCount(entityVoteCount - 1);
                }
            } else {
                if (userVote === 'user_upvote') {
                    setUpvotes(upvotes - 1);
                    setVoteCount(entityVoteCount - 1);
                } else if (userVote === 'user_downvote') {
                    setDownvotes(downvotes - 1);
                    setVoteCount(entityVoteCount + 1);
                }
            }

            setUserVote(newVoteType === 'user_unvote' ? null : type);
            if (onVote) onVote();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setShowAuthModal(true);
            } else {
                console.error('Error voting:', error);
            }
        }
    };

    return (
        <div className="vote-container">
            <div className="action upvote">
            <button onClick={() => handleVote('user_upvote')} className={userVote === 'user_upvote' ? 'active' : ''}>
            <UpvoteIcon />
            </button>
            </div>
            <div className="action vote-count">
                <span>{entityVoteCount}</span>
            </div>
            <div className="action upvote">
            <button onClick={() => handleVote('user_downvote')} className={userVote === 'user_downvote' ? 'active downvote' : 'downvote'}>
            <DownvoteIcon />
            </button>
            </div>
            {showAuthModal && <AuthModal show={showAuthModal} onClose={() => setShowAuthModal(false)} />}
        </div>
    );
};

export default Vote;
