import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CircularProgress, Grid } from '@mui/joy';
import api from '../config/axiosConfig';
import EnemyCard from './EnemyCard';
import ImpactCard from './ImpactCard';
import SolutionCard from './SolutionCard';

const UserProfileOverview = ({ username, currentUser }) => {
    const [content, setContent] = useState([]); // Combined and sorted entities and comments
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(null);

    // Function to fetch entities and comments
    const fetchUserOverview = async (username, page) => {
        setLoading(true);
        try {
            const [entitiesResponse, commentsResponse] = await Promise.all([
                api.get(`/api/entities`, {
                    params: {
                        username,
                        limit: 3,
                        offset: (page - 1) * 3,
                    },
                }),
                api.get(`/api/comments`, {
                    params: {
                        username,
                        limit: 3,
                        offset: (page - 1) * 3,
                    },
                })
            ]);

            const newEntities = entitiesResponse.data.map(entity => ({
                ...entity,
                type: 'entity',
                uniqueKey: `entity-${entity.eid}` // Add a unique key
            }));
            const newComments = commentsResponse.data.map(comment => ({
                ...comment,
                type: 'comment',
                uniqueKey: `comment-${comment.id}` // Add a unique key
            }));

            const combinedContent = [...newEntities, ...newComments];

            // Sort combined content by created_ts
            combinedContent.sort((a, b) => new Date(b.created_ts) - new Date(a.created_ts));

            setContent((prevContent) => [...prevContent, ...combinedContent]);

            if (combinedContent.length === 0) {
                setHasMore(false);
            }
        } catch (error) {
            setError('Error fetching user overview:', error);            
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch the first page of data when the component mounts
    useEffect(() => {
        fetchUserOverview(username, page);
    }, [username, page]);

    // Infinite scrolling logic
    const handleScroll = (e) => {
        if (loading || !hasMore) return;

        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
            setPage(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, hasMore]);

    const renderItemCard = (item) => {
        switch (item.type) {
            case 'entity':
                switch (item.entity_type) {
                    case 'enemy':
                        return <EnemyCard enemy={item.attributes} showMeta={true} showActions={true} truncateDescription={true} isEnemyProfilePage={false} currentUser={currentUser} isEntityPage={true}/>;
                    case 'impact':
                        return <ImpactCard impact={item.attributes} showMeta={true} showActions={true} truncateDescription={true} isImpactProfilePage={false} currentUser={currentUser} isEntityPage={true}/>;
                    case 'solution':
                        return <SolutionCard solution={item.attributes} showMeta={true} showActions={true} truncateDescription={true} isSolutionProfilePage={false} currentUser={currentUser} isEntityPage={true}/>;
                    default:
                        return null;
                }
            case 'comment':
                return (
                    <Card variant="outlined" sx={{ p: 2 }}>
                        <Typography level="h6" sx={{ mb: 1 }}>
                            {username}
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            {item.content}
                        </Typography>
                        <Typography level="body3" sx={{ textAlign: 'right', color: 'neutral.500' }}>
                            {new Date(item.updated_ts).toLocaleString()}
                        </Typography>
                    </Card>
                );
            default:
                return null;
        }
    };

    if (loading && page === 1) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body2" color="error">{error}</Typography>;
    }

    return (
        <Box sx={{ my: 2 }}>
            <Box sx={{ mb: 2 }}>
                <Typography variant="body2">Sort by: New</Typography>
            </Box>

            <Grid container spacing={1}>
                {content.map((item) => (
                    <Grid xs={12} key={item.uniqueKey}>
                        {renderItemCard(item)}
                    </Grid>
                ))}
            </Grid>

            {loading && <CircularProgress />}
        </Box>
    );
};

export default UserProfileOverview;
