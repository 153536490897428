import React, { useEffect, useState } from 'react';
import api from '../config/axiosConfig';
import AddImpact from './AddImpact';
import ImpactCard from './ImpactCard';
import { CircularProgress, Box, Button, Option, Select, Typography } from '@mui/joy';
import './EnemyProfile.css';

const ImpactsSection = ({ currentUser, enemy, showAuthModal }) => {
    const [impacts, setImpacts] = useState([]);
    const [error, setError] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [scopeFilter, setScopeFilter] = useState('All');
    const [impactTypeFilter, setImpactTypeFilter] = useState('All');
    const [sortField, setSortField] = useState('updated_ts');
    const [sortOrder, setSortOrder] = useState('desc');

    useEffect(() => {
        const fetchImpacts = async () => {
            try {
                const response = await api.get(`/api/impacts/enemies/${enemy.id}`);
                setImpacts(response.data);
            } catch (err) {
                setError('Failed to load impacts. Please try again later.');
            }
        };

        if (enemy) {
            fetchImpacts();
        }
    }, [enemy]);

    const handleImpactAdded = async () => {
        // Re-fetch impacts after an impact is added
        const response = await api.get(`/api/impacts/enemies/${enemy.id}`);
        setImpacts(response.data);
    };

    const handleScopeChange = (e, newValue) => setScopeFilter(newValue);
    const handleImpactTypeChange = (e, newValue) => setImpactTypeFilter(newValue);
    const handleSortChange = (field) => {
        const order = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const handleAddImpactButtonClick = () => {
        if (!currentUser) {
            showAuthModal(true);
        } else if (openModal) {
            setOpenModal(false);
        } else {
            setOpenModal(true);
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const filteredImpacts = impacts.filter(impact => {
        const matchesScope = scopeFilter === 'All' || impact.scope === scopeFilter;
        const matchesImpactType = impactTypeFilter === 'All' || impact.rel_type === impactTypeFilter;
        return matchesScope && matchesImpactType;
    });

    const sortedImpacts = filteredImpacts.sort((a, b) => {
        if (sortOrder === 'asc') {
            return new Date(a[sortField]) - new Date(b[sortField]);
        } else {
            return new Date(b[sortField]) - new Date(a[sortField]);
        }
    });

    if (error) {
        return <div>{error}</div>;
    }

    if (!enemy) {
        return <div><CircularProgress size="lg"/></div>;
    }

    if (!impacts) {
        return <div><CircularProgress size="lg" sx={{ justifyContent: 'center'}}/></div>;
    }

    return (
        <Box>
        {openModal && (
            <AddImpact enemyId={enemy.id} onImpactAdded={handleImpactAdded} currentUser={currentUser} showAuthModal={showAuthModal} openModal={handleAddImpactButtonClick} onClose={handleCloseModal}/>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Select value={scopeFilter} onChange={handleScopeChange} size="sm">
                    <Option value="All">Scope</Option>
                    <Option value="Local">Local</Option>
                    <Option value="National">National</Option>
                    <Option value="Global">Global</Option>
                </Select>
                <Select value={impactTypeFilter} onChange={handleImpactTypeChange} size="sm">
                    <Option value="All">Type</Option>
                    <Option value="impact_negative">Negative</Option>
                    <Option value="impact_positive">Positive</Option>
                </Select>
                <Button size="sm" variant="plain" onClick={() => handleSortChange('updated_ts')}>
                    Updated {sortOrder === 'asc' ? '▲' : '▼'}
                </Button>
            </Box>
            <Button variant="outlined" onClick={handleAddImpactButtonClick}>
                Add Impact
            </Button>
        </Box>
        {impacts.length === 0 ? (
                <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
                    No impacts exist for this enemy.
                </Typography>
            ) : (
        sortedImpacts.map(impact => (
            <ImpactCard key={impact.impact_id} impact={impact} truncateDescription={true} currentUser={currentUser} />
        ))
        )}
    </Box>
    );
};

export default ImpactsSection;