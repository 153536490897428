import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CommentsSection from './CommentsSection';
import SolutionsSection from './SolutionsSection';
import ImpactsSection from './ImpactsSection';
import { Box, Tabs, Tab, TabList, TabPanel, Container} from '@mui/joy';
import './EnemyProfile.css';

const EnemyProfileTabs = ({ currentUser, showAuthModal, enemy }) => {
    const { name, tab } = useParams();
    const [selectedTab, setSelectedTab] = useState(tab || 'comments');

    const handleTabChange = (e, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box>
            <Container>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="Enemy Profile Tabs"
                size="lg"
                sx={{
                    '--Tabs-gap': '8px',
                    '--Tabs-radius': '12px',
                    '--Tabs-color': 'neutral.outlinedActiveBg'
                }}
            >
                <TabList 
                    sticky='top'
                    sx={{ backgroundColor: 'background.surface', borderRadius: '8px', p: 1 }}>
                    <Tab
                        component={Link}
                        to={`/enemy/${name}/comments`}
                        value="comments"
                        disableIndicator={true}
                        sx={{
                            borderRadius: '12px',
                            textDecoration: selectedTab === 'comments' ? 'underline' : 'none',
                            '&:hover': { textDecoration: 'underline', backgroundColor: 'neutral.200' },
                        }}
                    >
                        Comments
                    </Tab>
                    <Tab
                        component={Link}
                        to={`/enemy/${name}/impacts`}
                        value="impacts"
                        disableIndicator={true}
                        sx={{
                            borderRadius: '12px',
                            textDecoration: selectedTab === 'impacts' ? 'underline' : 'none',
                            '&:hover': { textDecoration: 'underline', backgroundColor: 'neutral.200' },
                        }}
                    >
                        Impacts
                    </Tab>
                    <Tab
                        component={Link}
                        to={`/enemy/${name}/solutions`}
                        value="solutions"
                        disableIndicator={true}
                        sx={{
                            borderRadius: '12px',
                            textDecoration: selectedTab === 'solutions' ? 'underline' : 'none',
                            '&:hover': { textDecoration: 'underline', backgroundColor: 'neutral.200' },
                        }}
                    >
                        Solutions
                    </Tab>
                </TabList>
                <TabPanel value="comments">
                    <CommentsSection commentableId={enemy.id} commentableType="enemy" currentUser={currentUser} />
                </TabPanel>
                <TabPanel value="impacts">
                    <ImpactsSection enemy={enemy} currentUser={currentUser} showAuthModal={showAuthModal} />
                </TabPanel>
                <TabPanel value="solutions">
                    <SolutionsSection enemy={enemy} currentUser={currentUser} />
                </TabPanel>
            </Tabs>
            </Container>
        </Box>
    );
};

export default EnemyProfileTabs;