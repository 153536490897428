import React, { useState, useEffect } from 'react';
import api from '../config/axiosConfig';
import AuthModal from './AuthModal';
import { useNavigate} from 'react-router-dom';
import { Textarea, Button, Input, CircularProgress } from '@mui/joy';
import './SolutionProfile.css';

const SolutionEditor = ({ currentUser, solution, description, referenceLink, editMode = true }) => {
    const navigate = useNavigate();
    const [newDescription, setDescription] = useState(description);
    const [newReferenceLink, setReferenceLink] = useState(referenceLink);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [authorized, setAuthorized] = useState(true); 

    useEffect(() => {
      if (!currentUser) {
        setAuthorized(false);  
        setShowAuthModal(true);
      } else if (currentUser.id !== solution.user_id) {
        setAuthorized(false);  
        navigate(`/solution/${solution.id}`);
        window.location.reload();
      }
    }, [currentUser, solution, navigate]);

    if (!authorized) {
      return null; // Prevent rendering if not authorized
    }

    const handleReferenceLinkChange = (e) => {
        setReferenceLink(e.target.value);
      };
    const handleDescriptionChange = (e) => {
      setDescription(e.target.value);
    };
  
    const handleSave = async (e) => {
      e.preventDefault();
      try {
          await api.patch(`/api/solutions/id/${solution.id}`, { description: newDescription, reference_link: newReferenceLink });
          editMode = false;
          navigate(`/solution/${solution.id}`);
          window.location.reload();
      } catch (error) {
          console.error('Error saving solution:', error);
      }
    };
    
    const handleCancel = () => {
        editMode = false;
        navigate(`/solution/${solution.id}`);
        window.location.reload();
    };

    if (!solution) {
        return <div><CircularProgress /></div>;
    }

    return (
        <div className="card-margin">
            <div> 
            {solution && (        
            <div className="edit-solution-card">
              <h3 className="solution-title">{solution.id} {solution.title}</h3>
                <Textarea
                label="Description"
                minRows={8}
                maxRows={1024}
                defaultValue={description}
                onChange={handleDescriptionChange}
              />
              <Input
                label="Reference Link"
                type="url"
                defaultValue={referenceLink}
                onChange={handleReferenceLinkChange}
                fullWidth
              />
              <div className="edit-actions">
                <Button variant="contained" color="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save
                </Button>
              </div>
              {solution.reference_link && (
                <a href={solution.reference_link} target="_blank" rel="noopener noreferrer" className="solution-card-link">
                    {solution.reference_link}
                </a>
            )}
            </div>
          )}
          </div>
          {showAuthModal && <AuthModal show={showAuthModal} onClose={() => setShowAuthModal(false)} />}
        </div>
    );
};

export default SolutionEditor;