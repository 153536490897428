import React, { useState } from 'react';
import { Modal, ModalDialog, Button, FormControl, FormLabel, Input, Select, Option, Textarea, FormHelperText, Stack } from '@mui/joy';
import api from '../config/axiosConfig';

const AddImpact = ({ enemyId, onImpactAdded, currentUser, showAuthModal, openModal, onClose }) => {
    const [referenceLink, setReferenceLink] = useState('');
    const [scope, setScope] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [impactType, setImpactType] = useState('impact_negative'); // Default to negative impact
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleAddImpact = async (e) => {
        e.preventDefault();
        if (!currentUser) {
            showAuthModal(true);
            return;
        }

        try {
            let standardizedLink = referenceLink.trim();

            // Check if the link starts with "https://", otherwise replace it
            if (!standardizedLink.startsWith('https://')) {
                standardizedLink = standardizedLink.replace(/^(.*?:\/\/)?/, 'https://');
            }

            const impactData = {
                reference_link: standardizedLink,
                scope,
                description,
                title,
                rel_type: impactType,
                user_id: currentUser.id,
            };

            const response = await api.post(`/api/impacts/enemies/${enemyId}`, impactData);

            const newImpact = response.data;

            // Automatically upvote the solution
            await api.post(`/api/votes/impact/${newImpact.impact_id}`, {
                user_id: currentUser.id,
                rel_type: 'user_upvote'
            });

            setSuccess('Impact added successfully!');
            setError('');
            onImpactAdded(newImpact); // Notify parent component to refresh impacts
            setReferenceLink(''); // Clear form fields after successful submission
            setScope('');
            setDescription('');
            onClose(); // Close modal after successful submission
        } catch (err) {
            if (err.response && err.response.status === 401) {
                showAuthModal(true);
            } else {
                setError('Failed to add impact. Please try again.');
                setSuccess('');
            }
        }
    };

    const handleScopeSelect = (e, newValue) => setScope(newValue);
    const handleImpactTypeSelect = (e, newValue) => setImpactType(newValue);
    const handleDescriptionInput = (e) => setDescription(e.target.value);
    const handleTitleInput = (e) => setTitle(e.target.value);
    const handleReferenceLinkInput = (e) => setReferenceLink(e.target.value);


    return (
        <>
            <Modal open={true} onClose={openModal}>
                <ModalDialog>
                    <form onSubmit={handleAddImpact}>
                        <FormControl required>
                            <FormLabel>Title</FormLabel>
                            <Input
                                type="text"
                                value={title}
                                onChange={handleTitleInput}
                                maxLength="300"
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>Reference Link</FormLabel>
                            <Input
                                type="url"
                                value={referenceLink}
                                onChange={handleReferenceLinkInput}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>Scope</FormLabel>
                            <Select value={scope} onChange={handleScopeSelect}>
                                <Option value="">Select Scope</Option>
                                <Option value="Local">Local</Option>
                                <Option value="National">National</Option>
                                <Option value="Global">Global</Option>
                            </Select>
                        </FormControl>
                        <FormControl required>
                            <FormLabel>Impact Type</FormLabel>
                            <Select value={impactType} onChange={handleImpactTypeSelect}>
                                <Option value="impact_negative">Negative</Option>
                                <Option value="impact_positive">Positive</Option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={description}
                                onChange={handleDescriptionInput}
                                maxLength="4000"
                                minRows={8}
                                maxRows={1024}
                                placeholder="Describe the impact..."
                            />
                        </FormControl>
                        {error && <FormHelperText color="danger">{error}</FormHelperText>}
                        {success && <FormHelperText color="success">{success}</FormHelperText>}
                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="plain" onClick={openModal}>Cancel</Button>
                        <Button type="submit">Add Impact</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </>
    );
};

export default AddImpact;
