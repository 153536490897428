import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import eoeLogo from '../eoe_logo_nobg.png';
import './Header.css';
import api from '../config/axiosConfig';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AddEnemy from './AddEnemy';
import { ReactComponent as PlusIcon } from '../assets/icon-plus.svg';

const Header = ({ currentUser, showAuthModal }) => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [openAddEnemyModal, setOpenAddEnemyModal] = useState(false);

    const settings = [
        {
            label: (
                <>
                    Profile
                    <br />
                    {currentUser?.username}
                </>
            ),
            icon: <Avatar alt="Profile" sx={{ width: 24, height: 24, mr: 0.7 }} />,
            link: `/user/${currentUser?.username}`,
        },
      { label: 'Settings', icon: <SettingsIcon fontSize='large' sx={{ color: '#555555', mr: 1 }} />, link: '/settings/' },
      { label: 'Logout', icon: <LogoutIcon fontSize='large' sx={{ color: '#555555', mr: 1 }} />, link: '/logout' },
    ];

    useEffect(() => {
        if (searchQuery.length > 0) {
            const fetchSuggestions = async () => {
                try {
                    const response = await api.get(`/api/enemies?search=${searchQuery}`);
                    setSuggestions(response.data);
                } catch (error) {
                    console.error('Error fetching suggestions:', error);
                }
            };
            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [searchQuery]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    
    const handleAddEnemyButtonClick = () => {
        if (!currentUser) {
            showAuthModal(true);
        } else if (openAddEnemyModal) {
            setOpenAddEnemyModal(false);
        } else {
            setOpenAddEnemyModal(true);
        }
    }

    const handleLoginButtonClick = () => {
        if (!currentUser) {
            showAuthModal(true);
        } else {
            navigate('/');
        }
    }

      const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
      };
    
      const handleCloseUserMenu = () => {
        setAnchorElUser(null);
      };

      return (
        <AppBar position="sticky" variant="soft" sx={{borderBottom: '0.1rem solid #c8c8c8', '--AppBar-background': 'white', '--AppBar-color': 'neutral'}}>
            <Container maxWidth="xxl">
                <Toolbar disableGutters>
                    <a href="/">
                        <img src={eoeLogo} alt="Enemies of Earth Logo" className="logo" />
                    </a>
                    <div className="search-container">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search enemies..."
                            className="search-input"
                        />
                        {suggestions.length > 0 && (
                            <ul className="suggestions-list">
                                {suggestions.map((suggestion) => (
                                    <li key={suggestion.id}>
                                        <Link to={`/enemy/${encodeURIComponent(suggestion.name.replace(/ /g, '_'))}`}>
                                            {suggestion.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    </Box>

                    {currentUser ? (
                        <>
                            <Box sx={{ flexGrow: 0, display: { md: 10, xs: 'block' } }}>
                            <button className="add-enemy-button add-enemy-button-act" onClick={handleAddEnemyButtonClick}>
                                <span style={{ display: 'inline-flex', alignItems: 'center', verticalAlign: 'middle' }}>
                                    <PlusIcon />
                                </span>
                                <span style={{ marginLeft: '4px', verticalAlign: 'middle' }}>Enemy</span>
                            </button>
                                {openAddEnemyModal && (<AddEnemy currentUser={currentUser} open={openAddEnemyModal} onClose={() => setOpenAddEnemyModal(false)} />)}
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt="User" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={() => handleCloseUserMenu(null)}
                                >
                                    {settings.map((userButton) => (
                                        <MenuItem key={userButton.label} component={Link} to={userButton.link} onClick={handleCloseUserMenu}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {userButton.icon}
                                                <Typography textAlign="left" sx={{ ml: 1 }}>
                                                    {userButton.label}
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </>
                    ) : (
                        <button className="add-enemy-button add-enemy-button-act" onClick={handleLoginButtonClick}>
                            Login
                        </button>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;