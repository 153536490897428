import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './components/MainPage';
import AuthLogout from './components/AuthLogout';
import AddEnemy from './components/AddEnemy';
import EnemyProfile from './components/EnemyProfile';
import EnemiesList from './components/EnemiesList';
import UserProfile from './components/UserProfile';
import SolutionProfile from './components/SolutionProfile';
import ImpactProfile from './components/ImpactProfile';
import Header from './components/Header';
import UserSettings from './components/UserSettings';
import api from './config/axiosConfig';
import AuthModal from './components/AuthModal';
import AuthLogin from './components/AuthLogin';
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
  } from '@mui/material/styles';
  import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
  import CssBaseline from '@mui/material/CssBaseline';

const materialTheme = materialExtendTheme();

function App() {
    const [user, setUser] = useState(null);
    const [awaitingUser, setAwaitingUser] = useState(true);
    const [showAuthModal, setShowAuthModal] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get('/api/user');
                setUser(response.data);
                setShowAuthModal(false);
            } catch (error) {
                console.error('Error fetching user:', error);
            } finally {
                setAwaitingUser(false);
            }
        };

        fetchUser();
    }, []);

    return (
        <Router>
            <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
            <JoyCssVarsProvider>
            <CssBaseline enableColorScheme />
            <div className="App">
                <Header currentUser={user} showAuthModal={setShowAuthModal}/>                
                <Routes>
                    <Route path="/" element={<MainPage currentUser={user} />} />
                    <Route path="/:sort" element={<MainPage currentUser={user} />} />
                    <Route path="/logout" element={<AuthLogout />} /> 
                    <Route path="/login" element={<AuthLogin awaitingUser={awaitingUser} currentUser={user}/>} /> 
                    <Route path="/add-enemy" element={<AddEnemy currentUser={user} />} />
                    <Route path="/enemy/:name/" element={<EnemyProfile currentUser={user} />} />
                    <Route path="/enemy/:name/:tab" element={<EnemyProfile currentUser={user} showAuthModal={setShowAuthModal}/>} />
                    <Route path="/enemies" element={<EnemiesList currentUser={user}/>} />
                    <Route path="/settings/" element={<UserSettings currentUser={user} awaitingUser={awaitingUser}/>} />
                    <Route path="/settings/:tab" element={<UserSettings currentUser={user} awaitingUser={awaitingUser}/>} />
                    <Route path="/user" element={<UserProfile user={user} />} />
                    <Route path="/user/:username" element={<UserProfile currentUser={user}/>} />
                    <Route path="/user/:username/:tab" element={<UserProfile currentUser={user}/>} />
                    <Route path="/solution/:id" element={<SolutionProfile currentUser={user}/>} />
                    <Route path="/impact/:id" element={<ImpactProfile currentUser={user}/>} />
                </Routes>
                {showAuthModal && <AuthModal show={showAuthModal} onClose={() => setShowAuthModal(false)} />}    
            </div>
            </JoyCssVarsProvider>
            </MaterialCssVarsProvider>
        </Router>
    );
}

export default App;
